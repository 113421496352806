'use strict'

import {
  isFieldFromReferencedCollection,
  getReferenceFieldName,
  getFieldFromReferencedCollectionName,
} from '@wix/dbsm-common/src/reference-fields/fieldPath'
import { get } from 'lodash-es'

const getFieldTypeCreator = (schema, relatedSchemas) => fieldName => {
  if (isFieldFromReferencedCollection(fieldName)) {
    const referenceFieldName = getReferenceFieldName(fieldName)
    const fieldFromReferencedCollectionName =
      getFieldFromReferencedCollectionName(fieldName)
    const referencedCollectionName =
      schema && schema.fields[referenceFieldName]
        ? schema.fields[referenceFieldName].referencedCollection
        : null
    const referencedSchema =
      relatedSchemas && referencedCollectionName
        ? relatedSchemas[referencedCollectionName]
        : null

    return get(referencedSchema, [
      'fields',
      fieldFromReferencedCollectionName,
      'type',
    ])
  }
  return get(schema, ['fields', fieldName, 'type'])
}

export default getFieldTypeCreator
