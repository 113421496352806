'use strict'

import { pick } from 'lodash-es'
import getReferencedCollectionsNames from './getReferencedCollectionsNames'
import promiseRetry from '@wix/dbsm-common/src/promiseRetry'
import { TraceType } from '../logger/traceType'

const NUMBER_OF_SCHEMA_RETRIES = 5

const SCHEMA_FETCH_FAILURE_BREADCRUMB = (retry, error) =>
  TraceType.Breadcrumb({
    category: 'schema',
    message: `LOAD_SCHEMA_FAILURE retry ${retry}/${NUMBER_OF_SCHEMA_RETRIES}`,
    data: { error },
  })

const SCHEMA_FETCH_SUCCESS_BREADCRUMB = retry =>
  TraceType.Breadcrumb({
    category: 'schema',
    message: `LOAD_SCHEMA_SUCCESS after ${retry} retries`,
  })

export default (wixDataSchemasProxy, logger) => {
  let schemasPromise
  let cachedSchemas = null

  const fetchSchemas = collectionNames => {
    let schemaLoadRetryNum = 1
    const schemasRetryPromise = promiseRetry(
      () =>
        wixDataSchemasProxy
          .bulkGet(collectionNames, { referencedCollectionsDepth: 1 })
          .catch(error => {
            logger.trace(
              SCHEMA_FETCH_FAILURE_BREADCRUMB(schemaLoadRetryNum, error),
            )
            schemaLoadRetryNum++
            throw error
          }),
      NUMBER_OF_SCHEMA_RETRIES,
    )
    return schemasRetryPromise
      .then(schemas => {
        if (schemaLoadRetryNum > 1) {
          logger.error(new Error('Fetching schemas required retries'), {
            level: 'info',
          })
        }
        logger.trace(SCHEMA_FETCH_SUCCESS_BREADCRUMB(schemaLoadRetryNum))
        logger.addSessionData(() => ({ schemas }))
        cachedSchemas = schemas
        return schemas
      })
      .catch(error => {
        throw error
      })
  }

  const loadPrefetched = prefetchedSchemaData => {
    cachedSchemas = prefetchedSchemaData
    schemasPromise = Promise.resolve(cachedSchemas)
    return schemasPromise
  }

  const loadSchemas = collectionNames => {
    schemasPromise = fetchSchemas(collectionNames)
    return schemasPromise
  }

  const waitForSchemas = () => schemasPromise

  return {
    loadSchemas,
    loadPrefetched,
    waitForSchemas,
    getSchema: collectionName => getSchema(cachedSchemas, collectionName),
    getReferencedCollectionsSchemas: collectionName =>
      getReferencedCollectionsSchemas(cachedSchemas, collectionName),
  }
}

const getSchema = (schemas, collectionName) =>
  schemas ? schemas[collectionName] : null

const getReferencedCollectionsSchemas = (schemas, collectionName) => {
  const mainSchema = schemas ? schemas[collectionName] : null
  const referencedCollectionsNames = mainSchema
    ? getReferencedCollectionsNames(mainSchema)
    : null
  const referencedCollectionsSchemas =
    referencedCollectionsNames && schemas
      ? pick(schemas, referencedCollectionsNames)
      : null

  return referencedCollectionsSchemas
}
