'use strict'

const fromPairs_ = require('lodash/fromPairs')
const uuid = require('uuid')

module.exports = ({ requestFunction = global.fetch, elementorySupport }) => ({
  reportFormEventToAutomationCreator:
    ({ isPreview }) =>
    async ({ detailedEventPayload, eventUTCTime }) => {
      const url = `/_api/action-triggers-server/v1/report-event`

      const { instance } = fromPairs_(
        elementorySupport.queryParameters.split('&').map(e => {
          const splitIndex = e.indexOf('=')
          return [e.substring(0, splitIndex), e.substring(splitIndex + 1)]
        }),
      )

      const authorization = { Authorization: instance }
      const headers = {
        ...elementorySupport.options.headers,
        ...authorization,
      }
      const body = {
        eventIdentifier: {
          eventUniqueId: uuid.v4(),
          eventType: 'form/form/code',
          sourceUniqueId: '675bbcef-18d8-41f5-800e-131ec9e08762',
        },
        eventUTCTime,
        detailedEventPayload,
      }

      await requestFunction(url, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(body),
        headers,
      })
    },
})
