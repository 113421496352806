'use strict'

const { WIX_CODE } = require('@wix/app-definition-ids')

module.exports = ({ requestFunction = global.fetch }) => ({
  publishEditorFormsToAutomation: async ({ forms, editorSdkProxy }) => {
    const publicApi = await editorSdkProxy.document.application.getPublicAPI({
      appDefinitionId: WIX_CODE,
    })
    const { instance } = await publicApi.getWixCodeAuthParams()
    const url = `/_api/wix-form-builder-web/v1/publish-site`

    await requestFunction(url, {
      headers: {
        'content-type': 'application/json',
        Authorization: instance,
        'X-Wix-Client-Artifact-Id': 'wix-code-automations-client',
      },
      body: JSON.stringify({ forms }),
      cache: 'no-cache',
      credentials: 'same-origin',
      method: 'POST',
      redirect: 'follow',
      referrer: 'no-referrer',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(
            `Server error on automations endpoint | status: ${response.status} | statusText: "${response.statusText}"`,
          )
        }
      })
      .catch(error => {
        throw new Error(
          `Fetch exception at publishEditorFormsToAutomation: ${error}`,
        )
      })
  },
})
