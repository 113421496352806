'use strict'

import { values, map, pickBy, uniq } from 'lodash-es'

const hasReferencedCollection = field => !!field.referencedCollection
const getReferencedCollection = field => field.referencedCollection

const _getReferencedCollectionsNames = fields => {
  const referendingFields = pickBy(fields, hasReferencedCollection)
  const references = values(referendingFields)
  const referencedCollections = map(references, getReferencedCollection)
  const uniqueReferencedCollectionsNames = uniq(referencedCollections)

  return uniqueReferencedCollectionsNames.filter(Boolean)
}

const getReferencedCollectionsNames = schema => {
  return schema.fields ? _getReferencedCollectionsNames(schema.fields) : null
}

export default getReferencedCollectionsNames
